:root {
  --ai-primary: #FFD700;
  --ai-secondary: #FF8C00;
  --ai-tertiary: #FF4500;
  --ai-background: #121212;
  --ai-surface: #1E1E1E;
  --ai-text: #E0E0E0;
}

body {
  background-color: var(--ai-background);
  color: var(--ai-text);
  font-family: 'Roboto', sans-serif;
}

.meme-generator-container {
  background: linear-gradient(135deg, rgba(30,30,30,0.9) 0%, rgba(18,18,18,0.9) 100%);
  border: 1px solid var(--ai-secondary);
  box-shadow: 0 0 20px rgba(255, 140, 0, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 15px;
}

h1, h2, h3 {
  background: linear-gradient(45deg, var(--ai-primary), var(--ai-tertiary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(255, 140, 0, 0.5);
}

input[type="text"], 
input[type="password"], 
textarea {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid var(--ai-secondary);
  color: var(--ai-text);
  transition: all 0.3s ease;
  border-radius: 8px;
}

input[type="text"]:focus, 
input[type="password"]:focus, 
textarea:focus {
  box-shadow: 0 0 15px rgba(255, 140, 0, 0.5);
  border-color: var(--ai-primary);
}

button {
  background: linear-gradient(45deg, var(--ai-primary), var(--ai-secondary), var(--ai-tertiary));
  border: none;
  color: var(--ai-background);
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

button:before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  transition: all 0.3s ease;
}

button:hover:before {
  left: 100%;
}

button:disabled {
  background: #333;
  color: #777;
}

.meme-result {
  border: 1px solid var(--ai-secondary);
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 15px rgba(255, 140, 0, 0.3);
  transition: all 0.3s ease;
  border-radius: 12px;
}

.meme-result:hover {
  transform: scale(1.02);
  box-shadow: 0 0 25px rgba(255, 140, 0, 0.5);
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(255, 140, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 140, 0, 0); }
}

.generate-button {
  animation: pulse 2s infinite;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--ai-background);
}

::-webkit-scrollbar-thumb {
  background: var(--ai-secondary);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--ai-tertiary);
}